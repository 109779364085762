<template>
  <div>
    <!-- <transition name="fade">
      <div v-show="loading" :style="{ position: 'absolute', height: '100%', width: '100%' }">
          <Loading />
      </div>
    </transition> -->
    <div tabindex="-1" v-if="isMounted">
      <!-- <slot /> -->
    </div>
    <div class="map-html-overlays">

      <transition name="fade">
        <RenderView2d v-if="renderView2d" :data="renderView2d" class="render-view-2d" />
      </transition>
      <div
        :class="['background-renderimage', { 'render-ready': renderReady }]"
        :style="backgroundStyle"
      ></div>
      <v-card
        v-if="renderView"
        title="Close 3D View"
        prepend-icon="mdi-close"
        class="pa-3 render-view-toggle"
      >
        <v-btn text large @click="reset">
          <v-icon left> mdi-sitemap-outline </v-icon> Network View
        </v-btn>
        <v-btn text large @click="returnToStation">
          <v-icon left> mdi-train </v-icon> Precinct View
        </v-btn>
      </v-card>
      <slot />
    </div>


  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { Camera } from "@egjs/view360";
import RenderView2d from "./RenderView2d.vue"

// import { extractFilenameFromHeader } from "../../../dist/pdfjs/build/pdf";
// import ApiService from "../services/api.service"

export default {
  name: "MapArc",
  components: { RenderView2d },
  data: () => ({
    isMounted: false,
    handler: {
      doubleClick: null,
      mouseWheel: null,
      mouseWheelToggle: null,
      dragStart: null,
      drag: null,
      keyDown: null,
      isWheelScrolled: false,
      
    },
    scrollTimeout: null,
    renderView: null,
    renderReady: false,
  }),
  props: [
    "selectedLayers",
    "mapZoom",
    "cameraTilt",
    "heading",
    "mapMinZoom",
    "mapMaxZoom",
    "mapCenter",
    "bottomLeftExtent",
    "topRightExtent",
    "portalItemId",
    "portalItemType",
    "filterJson",
    "layerOptions",
    "tokens",
    "basemapWidget",
    "showLegend",
    "legendOpacity",
    "renderLayer",
    "renderView2d",
  ],
  provide() {
    const self = this;
    return {
      get map() {
        return self.map;
      },
      get markerLayer() {
        return self.markerLayer;
      },
      get view() {
        return self.view;
      },
    };
  },

  created() {
    console.log("MapCreated");
    this.maps = [];
  },

  mounted() {
    console.log("MapMounted");
    console.log("[RENDER]", this.renderLayer);

    if (this.legendOpacity != null) {
      const style = document.createElement("style");
      style.id = "dynamic-legend-style"; // Add an ID for easy reference
      style.innerHTML = `.esri-legend { background-color: rgba(255, 255, 255, ${this.legendOpacity}); }`;
      document.head.appendChild(style);
    } else {
      const style = document.createElement("style");
      style.id = "dynamic-legend-style"; // Add an ID for easy reference
      style.innerHTML = `.esri-legend { background-color: rgba(255, 255, 255, 1); }`;
      document.head.appendChild(style);
    }
    // console.log( "ultima", this.bottomLeftExtent,
    // this.topRightExtent, Number(this.topRightExtent.x))
    this.view = undefined;
    this.map = undefined;
    this.markerLayer = undefined;

    // this.tokens = this.$store.state.map.esriTokens;

    // Get ArcGIS Tokens
    // ApiService.getEsriToken().then((response) => {

    // this.$store.state.map.tokens = response.data

    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/WebMap",
        "esri/views/MapView",
        "esri/WebScene",
        "esri/views/SceneView",
        "esri/identity/IdentityManager",
        "esri/widgets/Search",
        "esri/widgets/Expand",
        "esri/widgets/Legend",
        "esri/widgets/BasemapGallery",
        "esri/core/watchUtils",
        "esri/renderers/visualVariables/RotationVariable",
      ],
      {
        css: true,
      }
    ).then(
      ([
        WebMap,
        MapView,
        WebScene,
        SceneView,
        IdentityManager,
        Search,
        Expand,
        Legend,
        BasemapGallery,
        watchUtils,
        RotationVariable,
      ]) => {
        let self = this;
        // console.log(this.mapZoom);
        // Firstly any tokens for ArcGIS
        this.tokens.forEach(function (token) {
          // console.log("TOKEN!!!");
          // console.log({
          //   token: token.token,
          //   server: token.server_url,
          // });
          IdentityManager.registerToken({
            token: token.token,
            server: token.server_url,
          });
        });

        console.log("portalItemId: " + this.portalItemId);
        console.log("portalItemType: " + this.portalItemType);


  
        if (this.portalItemType == "webscene") {
          if (this.portalItemId !== null && this.portalItemId !== undefined) {
            this.map = new WebScene({
              portalItem: {
                id: this.portalItemId,
              },
            });
          } else {
            this.map = new WebScene({
              basemap: "topo",
              ground: "world-elevation",
            });
          }
          console.log("sassadasd");
          // Create the scene view
          this.view = new SceneView({
            container: this.$el,
            map: this.map,
            zoom: this.mapZoom,
            center: this.mapCenter,
            // extent

            // tilt: 100 || 0,
            // camera: {
            //   tilt: 360 || 0
            // }
          });
          this.view.constraints = {
            minZoom: this.mapMinZoom,
            rotationEnabled: this.isRotationEnabled,
            maxZoom: this.mapMaxZoom,
          };
          console.log("View", this.view)

        } //Just create a regular map if webscene isn't specified
        else {
          if (this.portalItemId !== null && this.portalItemId !== undefined) {
            this.map = new WebMap({
              portalItem: {
                id: this.portalItemId,
              },
            });
          } else {
            this.map = new WebMap({
              basemap: "topo",
            });
          }

          // Create the scene view
          if (this.bottomLeftExtent !== null || this.topRightExtent !== null) {
            this.view = new MapView({
              container: this.$el,
              map: this.map,
              zoom: this.mapZoom,
              center: this.mapCenter,
            });
            this.view.constraints = {
              geometry: {
                type: "extent",
                xmin: Number(this.bottomLeftExtent.x),
                ymin: Number(this.bottomLeftExtent.y),
                xmax: Number(this.topRightExtent.x),
                ymax: Number(this.topRightExtent.y),
              },
              minZoom: this.mapMinZoom,
              maxZoom: this.mapMaxZoom,
              rotationEnabled: this.isRotationEnabled,
            };
          } else {
            this.view = new MapView({
              container: this.$el,
              map: this.map,
              zoom: this.mapZoom,
              center: this.mapCenter,
            });
            this.view.constraints = {
              minZoom: this.mapMinZoom,
              maxZoom: this.mapMaxZoom,
              rotationEnabled: this.isRotationEnabled,
            };
          }
        }

        this.handler.mouseWheelToggle = this.view.on("mouse-wheel", () => {
          this.isWheelScrolled = true;
          this.$store.commit("map/setScrollZoom", this.isWheelScrolled);
          // Clear existing timeout if any
          if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
          }

          // Set a new timeout to reset the boolean value
          this.scrollTimeout = setTimeout(() => {
            this.isWheelScrolled = false;
            this.$store.commit("map/setScrollZoom", this.isWheelScrolled);
          }, 200);
        });

        this.view.ui.move(
          ["zoom", "navigation-toggle", "compass"],
          "top-right"
        );
        // this.view.popup.autoOpenEnabled = false;
        this.view.popup.autoCloseEnabled = true;
        this.view.popup.dockEnabled = false;
        this.view.popup.actions = [];
        this.view.popup.dockOptions = { buttonEnabled: false };

        // Change the cursor to pointer if we're hovering a marker
        this.view.on("pointer-move", function (evt) {
          var screenPoint = {
            x: evt.x,
            y: evt.y,
          };
          self.view.hitTest(screenPoint).then(function (response) {
            self.$el.style.cursor = "default";
            if (response.results.length > 0)
              if (response.results[0].graphic.layer === self.markerLayer)
                self.$el.style.cursor = "pointer";
          });
        });

        this.view.on("click", function (evt) {
          var screenPoint = {
            x: evt.x,
            y: evt.y,
          };

          console.log(evt.mapPoint.longitude);
          console.log(evt.mapPoint.latitude);

          self.view.hitTest(screenPoint).then(function (response) {
            if (
              response.results.length === 0 &&
              self.$store.state.mapTool === "comment"
            ) {
              self.$store.dispatch("map/placeComment", [
                evt.mapPoint.longitude,
                evt.mapPoint.latitude,
              ]);
            }
          });
        });

        // Fire an event when the map changes it's focus
        watchUtils.watch(self.view, "extent", function () {
          self.$emit("mapMoved", self.view.extent);
          self.$emit("mapZoomed", self.view.zoom);
        });

        //Add the default search widget
        const search = new Search({
          view: this.view,
        });
        this.view.ui.add(search, "top-left");

        // Add the legend widget

        const legend = new Expand({
          content: new Legend({
            view: this.view,
          }),
          view: this.view,
          expanded: this.showLegend,
        });
        this.view.ui.add(legend, "bottom-left");

        // Add the basemap widget
        const basemapGallery = new Expand({
          content: new BasemapGallery({
            view: this.view,
          }),
          view: this.view,
          expanded: false,
        });

        if (this.basemapWidget) {
          this.view.ui.add(basemapGallery, "bottom-right");
        }

        this.isMounted = true;
        self.$emit("mapLoadEnd");
        this.$emit("ready");
      }
    );
    // });
  },
  methods: {
    reset() {
      this.$store.commit("map/setResetMap", true);
      this.$store.commit("map/setResetJourney", true);
      this.$store.commit("map/setResetLocation", true);
      this.$store.commit("map/setSelectedLocation", null);
      this.$store.commit("map/setPrecinct", null);
      this.$store.commit("map/setGroupLocation", null);
      this.$store.commit("map/setRightSidebarZoomLevel", null);
      this.$store.commit("map/setBackupPrecinct", null);
      this.renderReady = false;
      this.$store.commit("map/setResetRenderLayer", true);
      setTimeout(() => {
        this.$store.commit("map/setRenderLocation", null);
      }, 1000);
      this.$store.commit("map/setHotspotGroupSelectedLocation", null);
      this.$store.commit("map/setJourney", {
        startLocation: null,
        stopLocation: null,
        entrance: null,
        exit: null,
      });
      this.$root.$emit("clear-render-view-2d")
    },
    returnToStation() {
      this.$store.commit("map/setResetRenderLayer", true);
      this.renderReady = false;
      this.$root.$emit("clear-render-view-2d")

      setTimeout(() => {
        this.$store.commit("map/setRenderLocation", null);
    
        this.$store.commit(
          "map/setPrecinct",
          this.$store.state.map.backupPrecinct
        );
        this.$store.commit("map/setBackupPrecinct", null);
      }, 500);

    },
    zoomToCenter: function (center, zoom, tilt) {
      //Reset Camera Tilt
      // this.cameraTilt = 0;
      this.$emit("resetTilt", 0);
      console.log("SET LOCATION GO TO", center, zoom, tilt, this.cameraTilt);
      this.view
        .goTo({
          center: center,
          zoom: zoom,
          tilt,
          heading: 0,
        })
        .catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
    },
    zoomToPoints: function (pointArray) {
      if (pointArray.length === 0) return;

      let self = this;
      loadModules(["esri/geometry/Multipoint"], {
        css: true,
      }).then(([Multipoint]) => {
        let multiPoint = new Multipoint({ points: pointArray });
        self.view.goTo(multiPoint).catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
      });
    },
    zoomToLocation: function (markerZoom, center) {
      this.$store.commit("map/setRenderLocation", null);
      let options = {
        duration: 2000,
        easing: "linear",

      };
      this.view.goTo(
        {
          target: center,
          heading: this.heading,
          zoom: markerZoom,
          tilt: this.cameraTilt,
        },
        options
      );

      if (this.selectedLocation) {
        console.log(this.view)

        // this.view.navigation.mouseWheelEnable = false
        this.handler.mouseWheel = this.view.on("mouse-wheel", function (event) {
          // prevents panning with the mouse drag event
          event.stopPropagation();
        });


        this.handler.drag = this.view.on("drag", (event) => {

          if(event.action = "end") {

            const deltaX = event.x - event.origin.x
            const deltaY = event.y - event.origin.y
            const mouseDistance = Math.sqrt(Math.pow(deltaX, 2), Math.pow(deltaY, 2))
            let angle = mouseDistance

            // Ensures that the camera doesn't take the shortest path.
            // If the angle is larger than 180, the camera will take 
            // the shortest route and therefore will go the opposite direction
            if(angle > 180) {
              angle = 180
            }

            let heading = 0

            // Rotate camera either left or right
            if(event.x > event.origin.x) {
               heading = Math.floor(this.view.camera.heading + angle)
            } else {
              heading = Math.floor(this.view.camera.heading - angle)
            }
            
            this.view.goTo({ heading: heading, center: center, zoom: markerZoom }, { speedFactor: 0.5 })
            .then((result) => {} )
            .catch((error) => {
              if(error.name !== "AbortError") console.log(error)
            })
    

          }
          event.stopPropagation();
        });


        this.handler.doubleClick = this.view.on(
          "double-click",
          function (event) {
            // prevents panning with the mouse drag event
            event.stopPropagation();
          }
        );
        this.handler.keyDown = this.view.on("key-down", function (event) {
          const keyPressed = event.key;
          if (keyPressed.slice(0, 5) === "Arrow") {
            event.stopPropagation();
          }
        });
        this.view.ui.remove("zoom");
        this.view.ui.move(["navigation-toggle", "compass"], "top-right");
        // this.view.popup.autoOpenEnabled = false;
        this.view.popup.autoCloseEnabled = true;
        this.view.popup.dockEnabled = false;
        this.view.popup.actions = [];
        this.view.popup.dockOptions = { buttonEnabled: false };
      }
    },
    zoomToRenderLocation: function (
      center,
      renderTilt,
      renderZoom,
      renderHeading,
      renderDuration
    ) {
      let options = {
        duration: renderDuration,
        easing: "linear",
        // camera: {
        //   tilt: this.cameraTilt || 100
        // }
      };

      this.view.goTo(
        {
          target: center,
          heading: renderHeading,
          zoom: renderZoom,
          tilt: renderTilt,
          // camera: {
          //   tilt: this.cameraTilt || 300
          // }
        },
        options
      );

      // this.view.navigation.mouseWheelEnable = false
      this.handler.mouseWheel = this.view.on("mouse-wheel", function (event) {
        // prevents panning with the mouse drag event
        event.stopPropagation();
      });
      let initialX = null;
      let initialHeading = null;

      this.handler.dragStart = this.view.on("pointer-down", (event) => {
        event.stopPropagation();
      });

      this.handler.drag = this.view.on("drag", (event) => {
        event.stopPropagation();
      });

      this.handler.doubleClick = this.view.on("double-click", function (event) {
        // prevents panning with the mouse drag event
        event.stopPropagation();
      });
      this.handler.keyDown = this.view.on("key-down", function (event) {
        const keyPressed = event.key;
        if (keyPressed.slice(0, 5) === "Arrow") {
          event.stopPropagation();
        }
      });
      this.view.ui.remove("zoom");
      this.view.ui.move(["navigation-toggle", "compass"], "top-right");
      // this.view.popup.autoOpenEnabled = false;
      this.view.popup.autoCloseEnabled = true;
      this.view.popup.dockEnabled = false;
      this.view.popup.actions = [];
      this.view.popup.dockOptions = { buttonEnabled: false };
    },
    applyFilters: function () {
      console.log("Updating layer filters");
      let self = this;
      self.view.layerViews.forEach(function (layerView) {
        layerView.filter = self.filterJson;
      });
    },
    applyOptions: function () {
     
      let self = this;

      // Reset all the Journey related layers
      // This is a really hacky way to determine whether a layer is journey related
      // Example journey layer name: "Anzac Station to Town Hall Town Hall to Anzac Station"
      self.view?.map.layers.forEach(function (layer) {
        const layerNameWords = layer.title.split("to")
        if(layerNameWords.length >= 2) {
          const firstWord = layerNameWords[0].trim()
          const lastWord = layerNameWords[layerNameWords.length - 1].trim()

          if(firstWord === lastWord) {
            layer.visible = false
          }
        }
      });

      self.view?.map.layers.forEach(function (layer) {
        if(self.layerVisibility) {
          if (layer.title in self.layerVisibility) {
            layer.visible = self.layerVisibility[layer.title];
          }
        } 
      });
    },
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.renderLayer ? `url(${this.renderLayer})` : "",
      };
    },
    resetMap() {
      return this.$store.state.map.resetMap;
    },
    hotspotGroupSelectedLocation() {
      return this.$store.state.map.hotspotGroupSelectedLocation;
    },
    selectedLocation() {
      return this.$store.state.map.selectedLocation;
    },
    renderLocation() {
      return this.$store.state.map.renderLocation;
    },
    layerVisibility() {
      let layers = {};
      let layerOptions = this.layerOptions;

      if(layerOptions && Object.keys(layerOptions).length > 0) {
        //Build a list of layers and their visibility status
        for (let group in layerOptions) {
          for (let layer in layerOptions[group]) {
            let visible = layerOptions[group][layer];
            layers[layer] = visible;
          }
        }
        return layers;
      }

    },
  },
  watch: {
    legendOpacity(newValue) {
      const style = document.querySelector("#dynamic-legend-style");
      if (style) {
        style.innerHTML = `.esri-legend { background-color: rgba(255, 255, 255, ${newValue}); }`;
      }
    },
    renderLayer: {
      handler(newValue, oldValue) {
        if (newValue != null) {          
          this.renderView = newValue;
          this.renderReady = true;
        } else {
          this.renderReady = false;
          this.renderView = null; // Set renderView to null after the transition
        }
      },
      deep: true,
    },
    resetMap: function (value) {
      if (value) {
        const center = [Number(this.mapCenter[0]), Number(this.mapCenter[1])];
        this.zoomToCenter(center, this.mapZoom, 0);
        this.$store.commit("map/setResetMap", false);
      }
    },
    hotspotGroupSelectedLocation: {
      async handler(value) {
        const center = [Number(value.location.lng), Number(value.location.lat)];
        if (value.zoomLevel != null) {
          console.log("SCROLL Watch", value.zoomLevel);
          this.zoomToLocation(Number(value.zoomLevel), center);
        } else {map
          this.zoomToLocation(18, center);
        }
      },
      deep: true,
    },
    selectedLocation: {
      async handler(value) {
        if (!value) {
          this.handler.mouseWheel.remove();
          this.handler.dragStart.remove();
          this.handler.drag.remove();
          this.handler.keyDown.remove();
          this.handler.doubleClick.remove();

          this.view.ui.add("zoom");
          this.view.ui.move(
            ["zoom", "navigation-toggle", "compass"],
            "top-right"
          );
          // this.view.popup.autoOpenEnabled = false;
          this.view.popup.autoCloseEnabled = true;
          this.view.popup.dockEnabled = false;
          this.view.popup.actions = [];
          this.view.popup.dockOptions = { buttonEnabled: false };
        }

      },
      deep: true,
    },
    renderLocation: {
      async handler(value) {
        if (!value) {
          this.handler.mouseWheel.remove();
          this.handler.dragStart.remove();
          this.handler.drag.remove();
          this.handler.keyDown.remove();
          this.handler.doubleClick.remove();

          this.view.ui.add("zoom");
          this.view.ui.move(
            ["zoom", "navigation-toggle", "compass"],
            "top-right"
          );
          // this.view.popup.autoOpenEnabled = false;
          this.view.popup.autoCloseEnabled = true;
          this.view.popup.dockEnabled = false;
          this.view.popup.actions = [];
          this.view.popup.dockOptions = { buttonEnabled: false };
        }
      },
      deep: true,
    },
    filterJson: function () {
      this.applyFilters();
    },

    layerVisibility: function () {
      this.applyOptions();

      // loadModules(["esri/layers/FeatureLayer", "esri/layers/TileLayer", "esri/layers/ImageryLayer"], {
      //   css: true,
      // }).then(([FeatureLayer, TileLayer]) => {
      //   view.map.layers.add(new TileLayer({
      //     url: self.url,
      //     id: self.name,
      //     opacity: self.opacity,
      //     visible: self.visible,
      //   }));
      // })
    },
    renderView2d(newValue) {
      // Show/hide Precinct and Network overlay
      this.renderView = newValue ? true : false;
    }
  },
};
</script>

<style lang="scss" scoped>
div {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.marker {
  position: absolute;
  /* top: 0px; */
  /* left: 0px; */
}

.centered-block {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  position: absolute;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative !important;
}

.esri-popup__main-container {
  max-width: 300px;
}

.esri-view-height-less-than-medium .esri-popup__main-container {
  max-height: 200px;
}
.esri-view-width-xlarge .esri-popup__main-container {
  width: 140px;
}

.esri-view-width-medium .esri-popup__main-container {
  width: 140px;
}

.map-html-overlays {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  pointer-events: None;
}

>>> .esri-ui {
  z-index: 2;
}

>>> .esri-view-width-xsmall
  .esri-expand--auto
  .esri-expand__container--expanded {
  position: relative;
}

.background-renderimage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease, background-image 1s ease; /* Add transition for background-image */
  opacity: 0; /* Start with a default opacity of 0 */
}

.render-ready {
  opacity: 1;
}

.render-view-2d {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: all;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.render-view-toggle {
  position: absolute;
  top: 15px;
  right: 60px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
  height: 65px;
  border-radius: 0;
  pointer-events: all;
  // z-index: +999;
}

@media (max-width: 1200px) {
  .render-view-toggle {
    display: flex;
    flex-direction: column;
    width: 140px;
    left: 18px;
    top: 60px;
    height: 96px;
    padding: 4px !important;

    .v-btn {
      font-size: 10px !important;
      padding: 4px;

      .v-btn__content {
        font-size: 10px !important;
        // display: none;

    
      }
    }
  }


}
</style>
