<template>

  <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="undefined"
    :captionText="data.caption" :accessibilityTextFlag="false">
    <iframe
      class="full-screen"
      :src="pdf"
      seamless="true"
      frameBorder="0"
    >
    </iframe>
  </LightboxContentLayout>

</template>

<script>
import {filesize} from "filesize"
import LightboxContentLayout from './LightboxContentLayout.vue';

export default {
  name: "LightboxDocumentCard",
  data: () => ({
    dialog: true,
    downloadLink: true,
  }),
  props: ["data"],
  components:{LightboxContentLayout},
  computed: {
    pdf() {
      console.log(this.data);
      if (this.data && this.data.file) return "/pdfjs/web/viewer.html?file=" + encodeURIComponent(this.data.file) + "#page=0&zoom=page-fit"; 
      return undefined;
    },
    size() {
      return filesize(this.data.file_size)
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined)
          return true
      return false
    },
  },
  methods: {
    openWindow: function (url, label) {
      console.log(label)
      window.open(url)
    },
  },
};
</script>


<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

</style>